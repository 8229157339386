<template>
  <div class="delta-value">
    <div class="delta-value__label">
      {{ label }}<trend-arrow :angle="angle"></trend-arrow>
    </div>
    <div class="delta-value__percentage">
      {{ percentage }}<span class="delta-value__unit">%</span>
    </div>
    <div class="delta-value__value">
      {{ value }}<span class="delta-value__unit">{{ currencySymbol }}</span>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import { range } from 'lodash';

import TrendArrow from './TrendArrow';

export default {
  name: 'currency-card',
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    percentage: {
      type: Number,
    },
    currencySymbol: {
      type: String,
    },
    trendDomain: {
      type: Array,
      default() {
        return [-20, 20];
      },
    },
  },
  computed: {
    angle() {
      const { trendDomain: domain, percentage } = this;

      const scale = d3
        .scaleQuantile()
        .domain(domain)
        .range(range(-90, 91, 5));

      return scale(percentage);
    },
  },
  components: {
    TrendArrow,
  },
};
</script>

<style lang="sass">
@import 'core'

.delta-value
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: stretch

  .trend-arrow
    display: inline-block
    margin-left: 8px
    width: 16px
    height: 16px
    align-self: flex-end
    font-size: 0

  > *
    text-align: right

.delta-value__label
  font-size: 1.4em
  border-bottom: 1px solid black
  margin-bottom: 4px

.delta-value__percentage,
.delta-value__value
  font-weight: 400
  font-size: 1.8em
  white-space: nowrap
  line-height: 1em

.delta-value__unit
  @include g-font-currency
  display: inline-block
  width: 24px
  text-align: right
</style>
