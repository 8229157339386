import cc from 'cryptocompare';

import types from './types';

export default {
  getMinuteHistory({ commit, state }, { fromSymbol, aggregate = 0 }) {
    const { currency: toSymbol } = state;
    const pair = `${fromSymbol}${toSymbol}`;

    return cc
      .histoMinute(fromSymbol, toSymbol, {
        aggregate,
      })
      .then(data => {
        commit(types.SET_HISTORY, {
          pair,
          data,
        });

        return pair;
      })
      .catch(console.error);
  },
  updateMinuteHistory({ dispatch, state }) {
    const { symbols } = state;

    return Promise.all(
      symbols.map(fromSymbol => dispatch('getMinuteHistory', { fromSymbol })),
    ).then(data => {
      console.log('all done', data);
    });
  },
};
