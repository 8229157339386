import moment from 'moment';

import DeltaValue from '@/components/DeltaValue';
import TrendArrow from '@/components/TrendArrow';
import ValueUnit from '@/components/ValueUnit';

export default {
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    trendAngle() {
      const { trend } = this.data;

      return {
        up: 45,
        unchanged: 0,
        down: -45,
      }[trend];
    },
    openLabel() {
      const duration = moment.duration(
        moment().diff(
          moment()
            .utc()
            .startOf('day'),
        ),
      );

      return `${duration.hours()}h`;
    },
  },
  components: {
    DeltaValue,
    TrendArrow,
    ValueUnit,
  },
};
