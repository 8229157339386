<template>
  <div class="trend-arrow">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g ref="body">
        <path
          d="M2 14l13.8.1-5.1 5.1c-.8.8-.9 2 0 2.8.8.8 2.1.8 2.9 0l10-10L13.4 1.9c-.8-.8-2-.9-2.9 0s-.8 2.1 0 2.9l5.1 5.1L2 10c-1 0-1.5 1-1.5 2S1 14 2 14z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'trend-arrow',
  props: {
    angle: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    setup() {
      const { angle } = this;

      const body = d3.select(this.$refs.body);

      body.datum({
        angle,
      });

      this.update();
    },
    update() {
      const { angle } = this;

      const body = d3.select(this.$refs.body);

      function rotateTween(angle) {
        return d => {
          const interpolate = d3.interpolate(d.angle, angle);
          return t => {
            d.angle = interpolate(t);
            return `rotate(${-d.angle} 12,12)`;
          };
        };
      }

      body
        .transition()
        .ease(d3.easePolyInOut.exponent(4))
        .duration(200)
        .attrTween('transform', rotateTween(angle));
    },
    handleAngleChange() {
      this.update();
    },
  },
  watch: {
    angle: 'handleAngleChange',
  },
  mounted() {
    this.setup();
  },
};
</script>

<style lang="sass">
@import 'core'
</style>
