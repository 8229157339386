<template>
  <div class="stream-chart">
    <svg :width="width" :height="height" ref="d3-svg">
      <defs>
        <clipPath id="clip">
          <rect :width="bodyWidth" :height="bodyHeight"></rect>
        </clipPath>
      </defs>
      <g ref="d3-body" :transform="bodyTransform">
        <g ref="d3-xAxis" class="axis axis--x"></g>
        <g ref="d3-yAxis" class="axis axis--y"></g>
        <g ref="d3-area" clip-path="url(#clip)">
          <path ref="d3-line" class="line" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import _ from 'lodash';
import * as d3 from 'd3';

export default {
  name: 'stream-chart',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    interval: {
      type: Number,
      default: 5,
    },
    points: {
      type: Number,
      default: 72,
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {
      margins: {
        top: 20,
        right: 60,
        bottom: 20,
        left: 20,
      },
      paddings: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    };
  },
  computed: {
    bodyWidth() {
      const { width, margins, paddings } = this;

      return (
        width - margins.left - paddings.left - paddings.right - margins.right
      );
    },
    bodyHeight() {
      const { height, margins, paddings } = this;

      return (
        height - margins.top - paddings.top - paddings.bottom - margins.bottom
      );
    },
    bodyTransform() {
      const { margins, paddings } = this;

      const x = margins.left + paddings.left;
      const y = margins.top + paddings.top;

      return `translate(${x},${y})`;
    },
    currentData() {
      const { data, interval, points } = this;
      const count = data.length;

      return _(data)
        .sortBy('time')
        .drop(count % interval)
        .chunk(interval)
        .takeRight(points)
        .map(d => {
          const time = _.first(d).time;
          const open = _.first(d).open;
          const close = _.last(d).close;
          const high = d3.max(d, d => d.high);
          const low = d3.min(d, d => d.low);

          return {
            time,
            open,
            close,
            high,
            low,
          };
        })
        .value();
    },
    count() {
      return this.currentData.length;
    },
  },
  methods: {
    setup() {
      this.update();
    },
    update() {
      const { currentData: data } = this;
      const { xAxis, yAxis, line, area } = this.$d3.refs;
      const { max, round, abs } = Math;

      const width = this.bodyWidth;
      const height = this.bodyHeight;

      const timeRange = d3
        .extent(data, d => d.time)
        .map(v => new Date(v * 1e3));
      let low = d3.min(data, d => d.low);
      let high = d3.max(data, d => d.high);
      const priceSpan = abs(high - low);
      low -= priceSpan * 0.1;
      high += priceSpan * 0.1;

      const xScale = d3
        .scaleTime()
        .domain(timeRange)
        .range([0, width]);

      const xScale2 = d3
        .scaleBand()
        .domain(data.map(d => d.time))
        .rangeRound([0, width])
        .paddingInner(0.5)
        .paddingOuter(0);

      const yScale = d3
        .scaleLinear()
        .domain([low, high])
        .range([height, 0]);

      /*
      const lineGenerator = d3
        .line()
        .curve(d3.curveStepAfter)
        .x(d => xScale(new Date(d.time * 1e3)))
        .y(d => yScale(d.low + (d.high - d.low) / 2));
      */

      xAxis.call(d3.axisBottom(xScale).tickSizeOuter(0));

      yAxis.call(d3.axisRight(yScale).tickSizeOuter(0));

      line.datum(data);

      const bar = area.selectAll('.bar').data(data);

      bar
        .enter()
        .append('g')
        .classed('bar', true)
        .attr('transform', d => `translate(${xScale2(d.time)},${yScale(low)})`)
        .append('rect');

      bar.exit().remove();

      xAxis.attr('transform', `translate(0,${yScale(low)})`);

      yAxis.attr('transform', `translate(${width},0)`);

      area
        .selectAll('.bar')
        .select('rect')
        .attr('width', () => xScale2.bandwidth());

      area
        .selectAll('.bar')
        .transition()
        .duration(400)
        .ease(d3.easeCubicOut)
        .attr(
          'transform',
          d => `translate(${xScale2(d.time)},${round(yScale(d.high))})`,
        )
        .select('rect')
        .attr('height', d =>
          round(max(1, abs(yScale(d.high) - yScale(d.low)))),
        );

      // line
      //   .transition()
      //     .duration(400)
      //     .ease(d3.easeCubicOut)
      //     .attr('d', lineGenerator);
    },
    handleCurrentDataChange() {
      this.update();
    },
  },
  watch: {
    currentData: 'handleCurrentDataChange',
  },
  mounted() {
    this.$d3 = {
      refs: {},
    };

    _(this.$refs)
      .pickBy((v, k) => /^d3-/.test(k))
      .each((v, k) => {
        this.$d3.refs[k.replace(/^d3-/, '')] = d3.select(v);
      });
  },
};
</script>

<style lang="sass">
@import 'core'

.stream-chart
  .line
    fill: none
    stroke: #000
    stroke-width: 1.5px

  .tick
    line
      display: none

  .axis--y
    .domain
      display: none

  .bar
    fill: black
</style>
