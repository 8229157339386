/* eslint-disable */

import { merge } from 'lodash';
import io from 'socket.io-client';

import CCC from '@/lib/ccc/ccc-streamer-utilities';

class CryptoCompareStreamer {

  constructor() {
    this.socket = io.connect('https://streamer.cryptocompare.com/');

    this.prices = {};
  }

  // Format: {SubscriptionId}~{ExchangeName}~{FromSymbol}~{ToSymbol}
  // Use SubscriptionId 0 for TRADE, 2 for CURRENT and 5 for CURRENTAGG
  // For aggregate quote updates use CCCAGG as market
  // ['5~CCCAGG~BTC~USD', '5~CCCAGG~ETH~USD']

  unpack (raw) {
    const { prices } = this;
    const { FROMSYMBOL, TOSYMBOL } = raw;
    const FSYM = CCC.STATIC.CURRENCY.getSymbol(FROMSYMBOL);
    const TSYM = CCC.STATIC.CURRENCY.getSymbol(TOSYMBOL);
    const PAIR = `${FROMSYMBOL}${TOSYMBOL}`;

    function round (v, decimals = 5) {
      const f = Math.pow(10, decimals);

      return Math.round(v * f) / f;
    }

    if (!prices.hasOwnProperty(PAIR)) {
      prices[PAIR] = {};
    }

    const data = merge(prices[PAIR], raw);

    if (data.hasOwnProperty('LASTTRADEID')) {
      data.LASTTRADEID = parseInt(data.LASTTRADEID).toFixed(0);
    }
    if (data.hasOwnProperty('OPEN24HOUR')) {
      data.CHANGE24HOUR = round(data.PRICE - data.OPEN24HOUR);
      data.CHANGE24HOURPCT = (data.CHANGE24HOUR / data.OPEN24HOUR * 100);
    }
    if (data.hasOwnProperty('OPENHOUR')) {
      data.CHANGEHOUR = round(data.PRICE - data.OPENHOUR);
      data.CHANGEHOURPCT = (data.CHANGEHOUR / data.OPENHOUR * 100);
    }

    return {
      ...data,
      PAIR,
      FSYM,
      TSYM,
    };
  }

  onMessage (message) {
    const messageType = message.substring(0, message.indexOf('~'));

    if (messageType === CCC.STATIC.TYPE.CURRENTAGG) {
      const result = CCC.CURRENT.unpack(message);
      const data = this.unpack(result);

      this.onData(data);
    }
  }

  unsubscribe (subs) {
    this.socket.emit('SubRemove', { subs });
    this.socket.off('m');
  }

  subscribe (subs) {
    this.socket.emit('SubAdd', { subs });
    this.socket.on('m', this.onMessage.bind(this));

    return {
      unsubscribe: () => {
        this.unsubscribe(subs);
      },
    };
  }
}

export default CryptoCompareStreamer;
