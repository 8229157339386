<template>
  <div class="value-unit">
    <div class="value-unit__value">{{ value }}</div>
    <div class="value-unit__unit">{{ unit }}</div>
  </div>
</template>

<script>
export default {
  name: 'value-unit',
  props: {
    value: {
      type: [Number, String],
    },
    unit: {
      type: String,
    },
  },
};
</script>

<style lang="sass">
@import 'core'

.value-unit__value
  display: inline-block
  font-size: 1em

.value-unit__unit
  display: inline-block
  margin-left: 0.1em
</style>
