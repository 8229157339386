import CCStreamer from './lib/CryptoCompareStreamer';

import store, { types } from '@/store';

class Streamer {
  constructor() {
    this.isStreaming = false;

    const streamer = (this.streamer = new CCStreamer());
    streamer.onData = this.onData.bind(this);
  }

  set subscriptions(a) {
    try {
      this._unsubscribe();
    } catch (e) {
      /**/
    }

    this._subscriptions = a;
    this._unsubscribe = this.streamer.subscribe(a);

    this.isStreaming = true;
  }
  get subscriptions() {
    return this._subscriptions;
  }

  onData(d) {
    store.commit(types.SET_PRICE, d);
  }
}

export default Streamer;
