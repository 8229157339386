<template>
  <div class="currency-card" v-if="data">
    <div class="currency-card__title"></div>
    <div class="currency-card__from-symbol">
      {{ data.fromSymbol }}
      <trend-arrow
        class="currency-card__trend"
        :angle="trendAngle"
      ></trend-arrow>
    </div>
    <div class="currency-card__price">
      {{ data.price
      }}<span class="currency-card__to-symbol">{{ data.toSymbol }}</span>
    </div>
    <div class="currency-card__time-ago">{{ data.timeAgo }}</div>
    <div class="currency-card__delta">
      <delta-value
        class="currency-card__h"
        :label="openLabel"
        :percentage="data.changehp"
        :value="data.changeh"
        :currency-symbol="data.tsym"
      ></delta-value>
      <delta-value
        class="currency-card__24h"
        label="24h"
        :percentage="data.change24hp"
        :value="data.change24h"
        :currency-symbol="data.tsym"
      ></delta-value>
    </div>
    <div class="currency-card__chart">
      <interval-navigation
        :value.sync="interval"
        :data="intervals"
      ></interval-navigation>
      <stream-chart
        :data="historicalData"
        :interval="interval"
        :height="220"
      ></stream-chart>
    </div>
  </div>
</template>

<script>
import StreamChart from './StreamChart';
import IntervalNavigation from './IntervalNavigation';

import Currency from '@/mixins/Currency';

export default {
  name: 'currency-card',
  mixins: [Currency],
  props: {
    historicalData: {
      type: Array,
    },
    intervals: {
      type: Array,
      default() {
        return [1, 3, 5, 10, 15, 20, 30, 45, 60];
      },
    },
  },
  data() {
    return {
      interval: 5,
    };
  },
  computed: {
    globalInterval() {
      try {
        return this.$store.state.settings.interval;
      } catch (e) {
        return undefined;
      }
    },
  },
  methods: {
    handleGlobalIntervalChange(v) {
      this.interval = v;
    },
  },
  watch: {
    globalInterval: 'handleGlobalIntervalChange',
  },
  components: {
    StreamChart,
    IntervalNavigation,
  },
  mounted() {
    this.handleGlobalIntervalChange(this.globalInterval);
  },
};
</script>

<style lang="sass">
=xy($x, $y)
  position: absolute
  top: $y
  left: $x

.currency-card
  border-radius: 0
  border: 1px solid black
  margin: 8px
  position: relative
  height: 240px

.currency-card__delta
  position: absolute
  top: 22px
  right: 613px
  display: flex
  width: 240px
  justify-content: flex-end

  .delta-value
    flex: 1 0 50%
    padding: 0 8px

.currency-card__title
  font-size: 2em
  padding: 8px
  margin: 0

.currency-card__from-symbol
  +xy(0, 0)
  font-size: 4em
  font-weight: bold
  padding: 8px
  margin: 0
  font-weight: 300

.currency-card__price
  +xy(0, 50px)
  font-size: 6em
  font-weight: bold
  padding: 8px
  margin: 0

.currency-card__to-symbol
  font-size: 0.5em
  padding-left: 4px

.currency-card__time-ago
  +xy(8px, 200px)
  font-size: 1.5em
  padding-left: 4px

.currency-card__trend
  display: inline-block
  width: 48px
  height: 48px

.currency-card__chart
  position: absolute
  bottom: 0
  right: 0
  width: 600px
  height: 240px

  .interval-navigation
    position: absolute
    top: 8px
    left: 20px
    z-index: 2

  .stream-chart
    position: absolute
    right: 0
    bottom: 8px
    z-index: 1
</style>
