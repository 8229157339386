<template>
  <div class="view grid-view">
    <div class="grid-view__list">
      <template v-if="count">
        <currency-tile
          v-for="(item, index) in items"
          :key="item.pair"
          :data-index="index"
          :data="item"
          :simple="isItemInView(item)"
          @click.native="scrollToCard(item)"
        ></currency-tile>
      </template>
    </div>
    <div ref="cards" class="grid-view__cards">
      <div class="grid-view__cards-list">
        <template v-if="count">
          <currency-card
            v-for="(item, index) in items"
            :key="item.pair"
            :data-pair="item.pair"
            :data-index="index"
            :data="item"
            :historical-data="getHistoricalData(item)"
          ></currency-card>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import raf from 'raf';
import { includes, debounce } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import CurrencyCard from '@/components/CurrencyCard';
import CurrencyTile from '@/components/CurrencyTile';

export default {
  name: 'grid-view',
  data() {
    return {
      scrolling: false,
      itemsInView: [],
    };
  },
  computed: {
    ...mapState(['history', 'currency']),
    ...mapGetters(['pairs', 'getFormattedPrice']),
    items() {
      return this.pairs
        .map(pair => this.getFormattedPrice(pair))
        .filter(v => v);
    },
    count() {
      return this.items.length;
    },
  },
  methods: {
    getHistoricalData({ pair }) {
      return this.history[pair];
    },
    scrollToCard({ pair }) {
      const el = document.querySelector(`[data-pair="${pair}"]`);
      const container = this.$refs.cards;
      const offset = 8;

      if (!el || !container) {
        return;
      }

      const ip = d3.interpolateNumber(container.scrollTop, el.offsetTop);

      d3.select({})
        .interrupt()
        .transition()
        .duration(1000)
        .ease(d3.easeExpOut)
        .tween('scrollToCard', () => t => {
          container.scrollTop = ip(t) - offset;
        });
    },
    scanCardsInViewport() {
      const { cards } = this.$refs;
      const els = cards.querySelectorAll('.currency-card');

      const containerHeight = cards.clientHeight;
      const containerOffset = cards.scrollTop;

      const inView = el => {
        const elOffset = el.offsetTop;
        const elHeight = el.clientHeight;

        return (
          elOffset >= containerOffset &&
          elOffset + elHeight < containerOffset + containerHeight
        );
      };

      const results = Array.from(els)
        .filter(el => inView(el))
        .map(el => el.dataset.pair);

      this.itemsInView = results;
    },
    isItemInView({ pair }) {
      return includes(this.itemsInView, pair);
    },
    update(force) {
      if (!this.scrolling && !force) {
        return;
      }

      this.scanCardsInViewport();

      raf(() => {
        this.update();
      });
    },
    handleItemsChange() {
      this.update(true);
    },
  },
  watch: {
    items: {
      handler: 'handleItemsChange',
      deep: true,
    },
  },
  components: {
    CurrencyCard,
    CurrencyTile,
  },
  mounted() {
    const clearScrolling = debounce(() => {
      this.scrolling = false;
    }, 500);

    this.$refs.cards.addEventListener('scroll', () => {
      this.scrolling = true;
      clearScrolling();
      this.update();
    });

    this.handleItemsChange(this.items);
  },
};
</script>

<style lang="sass">
@import 'core'

.grid-view
  +g-fit
  height: 100vh
  display: flex
  justify-content: stretch
  align-items: stretch

.grid-view__list
  flex: 0 0 240px
  overflow-x: hidden
  overflow-y: auto

.grid-view__cards
  flex: 1 1 auto
  overflow-x: hidden
  overflow-y: auto

.grid-view__cards-list
  padding-bottom: 100%
</style>
