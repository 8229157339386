import { without } from 'lodash';
import moment from 'moment';

import CCC from '@/lib/ccc/ccc-streamer-utilities';

export default {
  subscriptions({ symbols, currency }) {
    return without(symbols, currency).map(
      symbol => `5~CCCAGG~${symbol}~${currency}`,
    );
  },
  pairs({ symbols, currency }) {
    return without(symbols, currency).map(symbol => `${symbol}${currency}`);
  },
  getFormattedPrice({ prices }) {
    return pair => {
      if (!(pair in prices)) {
        return;
      }

      const data = prices[pair];
      const {
        FROMSYMBOL,
        TOSYMBOL,
        TSYM,
        CHANGE24HOUR,
        CHANGE24HOURPCT,
        CHANGEHOUR,
        CHANGEHOURPCT,
        PRICE,
        LASTUPDATE,
        FLAGS,
      } = data;

      const formatValue = CCC.convertValueToDisplay;

      const trend = {
        1: 'up',
        2: 'down',
        4: 'unchanged',
      }[FLAGS];

      return {
        pair,
        trend,
        tsym: TSYM,
        fromSymbol: FROMSYMBOL,
        toSymbol: TOSYMBOL,
        timeAgo: moment(LASTUPDATE, 'X').fromNow(),
        price: formatValue('', PRICE),
        changeh: formatValue('', CHANGEHOUR),
        changehp: Number(CHANGEHOURPCT.toFixed(2)),
        change24h: formatValue('', CHANGE24HOUR),
        change24hp: Number(CHANGE24HOURPCT.toFixed(2)),
      };
    };
  },
};
