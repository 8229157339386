import Vue from 'vue';
import Vuex from 'vuex';

import types from './types';
import state from './state';
import getters from './getters';
import actions from './actions';
import modules from './modules';
import mutations from './mutations';
import plugins from './plugins';

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules,
  plugins,
  strict: process.env.NODE_ENV !== 'production',
});

/* eslint-disable global-require */
if (module.hot) {
  module.hot.accept(
    ['./getters', './actions', './mutations', './modules'],
    () => {
      store.hotUpdate({
        getters: require('./getters'),
        actions: require('./actions'),
        mutations: require('./mutations'),
        modules: require('./modules'),
      });
    },
  );
}
/* eslint-enable global-require */

export { types, getters, actions, mutations };

export default store;
