<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

import Streamer from '@/services/streamer';
import exchangeRates from '@/services/exchangeRates';

const streamer = new Streamer();

export default {
  name: 'app',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isStreaming: ({ streamer }) => streamer.isStreaming,
    }),
    ...mapGetters(['subscriptions']),
  },
  methods: {
    ...mapActions(['updateMinuteHistory']),
    update() {
      this.updateMinuteHistory();
    },
    handleSubscriptionsChange(a) {
      streamer.subscriptions = a;
    },
  },
  watch: {
    subscriptions: 'handleSubscriptionsChange',
  },
  created() {
    this.handleSubscriptionsChange(this.subscriptions);

    this.update();

    exchangeRates.load();

    setInterval(() => {
      this.update();
    }, 6e4);
  },
};
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400|Fira+Sans+Condensed:300,400|Fira+Mono:400')

body
  font-family: 'Barlow Condensed', sans-serif
  font-variant-numeric: tabular-nums
  margin: 0

  .symbol
    font-family: 'Fira Sans Condensed', sans-serif
</style>
