import { merge } from 'lodash';

import Vue from 'vue';

import types from './types';

export default {
  [types.SET_PRICE]({ prices }, data) {
    const { FROMSYMBOL: fromSymbol, TOSYMBOL: toSymbol } = data;

    const pair = `${fromSymbol}${toSymbol}`;
    const mergedData = merge({}, prices[pair], data);
    Vue.set(prices, pair, mergedData);
  },
  [types.SET_HISTORY]({ history }, { pair, data }) {
    Vue.set(history, pair, data);
  },
};
