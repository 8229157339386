import cc from '@/api/cc';

function load() {
  return cc
    .priceFull(['BTC'], ['USD', 'EUR', 'CHF'])
    .then(prices => {
      console.log(prices);
    })
    .catch(console.error);
}

export default {
  load,
};
