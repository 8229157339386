<template>
  <div class="currency-tile" v-if="data">
    <div class="currency-tile__from-symbol">
      {{ data.fromSymbol }}
    </div>
    <value-unit
      class="currency-tile__delta"
      :value="data.change24hp"
      unit="%"
    />
    <transition name="fade">
      <div v-if="!simple" class="currency-tile__body">
        <div class="currency-tile__from-symbol">
          <span style="visibility: hidden">{{ data.fromSymbol }}</span>
          <trend-arrow
            v-if="!simple"
            class="currency-tile__trend"
            :angle="trendAngle"
          ></trend-arrow>
        </div>
        <div class="currency-tile__price">
          {{ data.price
          }}<span class="currency-tile__to-symbol">{{ data.toSymbol }}</span>
        </div>
        <div class="currency-tile__time-ago">{{ data.timeAgo }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import Currency from '@/mixins/Currency';

export default {
  name: 'currency-tile',
  mixins: [Currency],
  props: {
    simple: {
      type: Boolean,
    },
  },
};
</script>

<style lang="sass">
@import 'core'

=xy($x, $y)
  position: absolute
  top: $y
  left: $x

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.2s ease-out

.fade-enter,
.fade-leave-to
  opacity: 0

.currency-tile
  border-radius: 0
  border: 1px solid black
  margin: 8px
  position: relative
  height: 115px

.currency-tile__body
  +g-fit

.currency-tile__title
  font-size: 1em
  padding: 8px
  margin: 0

.currency-tile__from-symbol
  +xy(0, 0)
  font-size: 2em
  font-weight: bold
  padding: 8px
  margin: 0
  font-weight: 300

.currency-tile__price
  +xy(0, 26px)
  font-size: 3em
  font-weight: bold
  padding: 8px
  margin: 0

.currency-tile__to-symbol
  font-size: 0.5em
  padding-left: 4px

.currency-tile__time-ago
  +xy(8px, 90px)
  font-size: 1em
  padding-left: 4px

.currency-tile__trend
  display: inline-block
  width: 24px
  height: 24px

.currency-tile__delta
  font-size: 1.25em
  position: absolute
  top: 4px
  right: 4px
</style>
