<template>
  <div class="interval-navigation">
    <button
      v-for="v in data"
      :key="v"
      :value="v"
      :class="{
        'interval-navigation__item': true,
        'interval-navigation__item--active': v === value,
      }"
      @click="onIntervalClick"
    >
      {{ v }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'interval-navigation',
  props: {
    data: {
      type: Array,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    _value: {
      set(v) {
        this.$emit('update:value', v);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onIntervalClick({ target }) {
      const { value } = target;

      this._value = Number(value);
    },
  },
};
</script>

<style lang="sass">
.interval-navigation__item
  -webkit-appearance: none
  border: none
  background-color: transparent
  border-radius: 0
  outline: none

  &.interval-navigation__item--active
    box-shadow: 0 1px 0 0 black
</style>
